import { useStaticQuery, graphql } from 'gatsby';
import { getRichTextFromQuery } from '../common/utils';

const useContentfulTerms = () => {
  const { allContentfulPrivacy } = useStaticQuery(
    graphql`
    {
      allContentfulPrivacy(sort: {order: ASC, fields: order}) {
        edges {
          node {
            order
            title
            description {
              json
            }
          }
        }
      }
    }
    `
  );
  
  return getRichTextFromQuery(allContentfulPrivacy.edges);
};

export default useContentfulTerms;