import React from 'react';
import classnames from 'classnames';
import allContentfulPrivacy from '../hooks/allContentfulPrivacy';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import AccordionList from '../components/AccordionList';
import { ConfusionPreFooterLight } from '../components/PreFooter';
import styles from './styles/privacy.module.scss';

const Privacy = () => {
  const [info, ...accordionItems] = allContentfulPrivacy();

  return (
    <Layout>
      <SEO title="Privacy" />
      <div className={styles.header}>
        <div className="container">
          <div className={styles.title}>Privacy.</div>
          <div className={styles.subtitle}>
            How we collect, use, disclose and protect your personal information
          </div>
        </div>
      </div>
      <div className={classnames('container', styles.container)}>
        <div className={styles.disclaimer}>{info.content}</div>
        <AccordionList items={accordionItems} />
      </div>
      <ConfusionPreFooterLight />
    </Layout>
  );
};

export default Privacy;
